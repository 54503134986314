<template>
    <div class="top">
        <div class="header">
            <div class="left">
                <img
                    class="header_logo"
                    src="@/assets/Reman_Hogbisz_Logo_Transparent.png"
                    alt="Reman Hogbisz Logo"
                />
                <h1>
                    <span class="a1">HOG</span>
                    <span class="a2">AN </span>
                    <span class="a3">REM</span>
                    <span class="a4">BISZ</span>
                </h1>
            </div>
            <div class="right">
                <a href="mailto:contact@hogbisz.com">
                    <div class="contact">
                        <div class="contact_inner">
                            <div class="contact_text">CONTACT US</div>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="main_content">
            <div class="center">
                <img
                    class="logo"
                    src="@/assets/Reman_Hogbisz_Logo_Big.png"
                    alt="Reman Hogbisz Logo"
                />
                <h1>REMAN HOGBISZ</h1>
                <h2>MORE FROM US SOON...</h2>
            </div>
        </div>

        <div class="footer">
            <p class="copyright">
                ©Copyright 2021 Reman Hogbisz. Made with ❤️ and
                <span>sleep</span> in Spotsylvania, VA
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "HomePage",
};
</script>

<style scoped>
.top {
    font-family: "Archive";
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    background-color: var(--color2);
    color: var(--color3);
    letter-spacing: 0.6rem;
    text-shadow: 4px 4px rgba(20, 10, 70, 0.8);
}

/* Header */

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 7%;
    background-color: var(--color3-transparent);
}

.left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left > h1 > span {
    display: inline-block;
}

.a1 {
    animation: 2s a1_animation forwards;
    animation-delay: 2s;
}

.a3 {
    animation: 2s a3_animation forwards;
    animation-delay: 2s;
}

@keyframes a1_animation {
    from {
        transform: translateX(0px);
    }

    to {
        transform: translateX(9.8rem);
    }
}

@keyframes a3_animation {
    from {
        transform: translateX(0px);
    }

    to {
        transform: translateX(-9.8rem);
    }
}

@media (max-width: 700px) {
    .left > h1 {
        display: none;
    }
}

.header_logo {
    width: auto;
    height: 5rem;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact {
    /* padding: 0px; */
    width: 15rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}

.contact_inner {
    width: 90%;
    height: 60%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color1);
    cursor: pointer;
}

.contact_text {
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: normal;
    color: var(--color3);
}

a {
    text-decoration: none;
}

/* End Header */

/* Start Main Content */

@media (max-width: 700px) {
    .main_content {
        height: 75%;
    }
}

@media (min-width: 699px) {
    .main_content {
        height: 88%;
    }
}

.main_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.center {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (min-width: 895px) {
    .center > h1 {
        font-size: 6rem;
    }

    .center > h2 {
        font-size: 3rem;
    }
}

.center > h1 {
    padding: 0rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 0px;
    text-align: center;
}
.center > h2 {
    padding: 0rem;
    font-weight: bold;
    margin-top: 0px;
    text-align: center;
}

.logo {
    width: auto;
    height: 45%;
}

/* End Main Content */

/* Footer */

.footer {
    font-size: 1.5rem;
    width: 100%;
    height: 5%;
    letter-spacing: normal;
    text-shadow: none;
}

.copyright {
    font-family: "Zag";
    text-align: center;
    padding: 0px;
    margin: 0px;
}
@media (max-width: 480px) {
    .footer > p {
        margin-bottom: 0.5rem;
    }
}
.footer > p > span {
    text-decoration: line-through;
    text-decoration-color: var(--color3);
    text-decoration-thickness: 3px;
}

/* End Footer */
</style>
