<template>
    <HomePage></HomePage>
</template>

<script>
import HomePage from "./components/Homepage.vue";
export default {
    name: "home-page",
    components: {
        HomePage,
    },
};
</script>

<style>
:root {
    --color1: #045c4c;
    --color2: #0a2342;
    --color3: #d1dbda;
    --color3-transparent: #d1dbda13;
    --color4: #7bb2d9;
    --color5: #3c3d7c;
}

body {
    background-color: var(--color2);
}

@font-face {
    font-family: "Archive";
    src: url("@/assets/fonts/Archive.otf");
}

@font-face {
    font-family: "Zag";
    src: url("@/assets/fonts/Zag Regular.otf");
}

@font-face {
    font-family: "Zag Bold";
    src: url("@/assets/fonts/Zag Bold.otf");
}
</style>
